import React, {
  useId, useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useHumanAgePredictor } from 'contexts/HumanAgePredictionContext';

import stopStream from 'utils/stopStream';
import setVideoSource from 'utils/setVideoSource';

import useAgeDetectionStore from 'stores/ageDetectionStore';
import useConfigStore from 'stores/configStore';

import logger from 'services/logger';
import { AgePredictionErrorCodes, loggerMessages } from 'types/logger';
import { useTranslation } from 'react-i18next';
import useProgressStore from 'stores/progressStore';
// import EulerArcSVG from './svgs/EulerArcSVG';
import mixpanel from 'services/mixpanel';
import DetectionMessage from './DetectionMessage';
import ProgressCircleSVG from './svgs/ProgressCircleSVG';
import FullscreenLoader from './FullscreenLoader';
import {
  BottomArc, LeftArc, RightArc, TopArc,
} from './Arcs';
import LeftArrow from './Arrows/LeftArrow';
import RightArrow from './Arrows/RightArrow';
import Text from './Text';
import CompleteCircleSVG from './svgs/CompleteCircleSVG';
// import RotationSVG from './svgs/RotationSVG';
import ProgressArcSVG from './svgs/ProgressArcSVG';
import FaceAngleMessage from './FaceAngleMessage';
import CircleMaskSVG from './svgs/CircleMaskSVG';

function AgePrediction() {
  const id = useId();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [videoElReady, setVideoElReady] = useState(false);
  const { t } = useTranslation();

  const status = useAgeDetectionStore((state) => state.status);
  const centered = useAgeDetectionStore((state) => state.centered);
  const up = useAgeDetectionStore((state) => state.pitchUp);
  const down = useAgeDetectionStore((state) => state.pitchDown);
  const left = useAgeDetectionStore((state) => state.yawLeft);
  const right = useAgeDetectionStore((state) => state.yawRight);
  const step = useAgeDetectionStore((state) => state.step);
  const age = useAgeDetectionStore((state) => state.age);
  const faceDetected = useAgeDetectionStore((state) => state.faceDetected);
  const faces = useAgeDetectionStore((state) => state.faces);
  const step2Variant = useAgeDetectionStore((state) => state.step2Variant);
  const disableAgePrediction = useAgeDetectionStore((state) => state.disableAgePrediction);

  const progress = useProgressStore((state) => state.progress);

  const skipAgeDetection = useConfigStore((state) => state.skipAgeDetection);
  const { threshold } = useConfigStore((state) => state.humanConfig.ageDetection);
  const displayKeyFace = useConfigStore((state) => state.debug.displayKeyFace);
  const infiniteFaceCollection = useConfigStore((state) => state.debug.infiniteFaceCollection);
  const percentToComplete = useConfigStore((state) => state.humanConfig.faceAngleStep2.percentToComplete);
  const numAngles = useConfigStore((state) => state.humanConfig.faceAngleStep2.numAngles);

  const human = useHumanAgePredictor();

  useEffect(() => {
    if (disableAgePrediction) {
      // Still collect faces for ID face matching
      return () => {};
    }
    /**
     * The only time agePredictorActive should be false is when the user
     * navigates back to this page after navigating away from it
     * NOTE: human.agePredictorActive is a constant value, no need for deps
     */
    (async () => {
      if (!human.agePredictorActive) {
        await human.initAgePredictWorker();
        logger.debug('Age prediction worker initialized.');
      }
    })();
    return () => {
      human.terminateAgePredictWorker();
      logger.debug('Age prediction worker terminated.');
    };
  }, [disableAgePrediction, human]);

  useEffect(() => {
    // Handle case where user clicks the browser back button
    // - stop the video stream
    // - end the recursion
    const video = videoRef.current;
    const handlePopState = () => {
      human.breakRecursiveAgePrediction();
      if (!video) {
        return;
      }

      const mediaStream = video.srcObject as MediaStream;
      stopStream(mediaStream);
      video.srcObject = null;
    };
    window.onpopstate = handlePopState;
  }, []);

  const stopStreamAndNavigate = useCallback((nextLocation: string) => {
    try {
      const video = videoRef.current;
      if (video) {
        const mediaStream = video.srcObject as MediaStream;
        stopStream(mediaStream);
        video.srcObject = null;
      }
      navigate(nextLocation);
    } catch (err) {
      console.error('Error stopping stream and navigating', err);
      navigate(nextLocation);
    }
  }, [navigate]);

  useEffect(() => {
    const awaitSetVideoSource = async () => {
      if (!videoRef.current) {
        return;
      }
      await setVideoSource(videoRef.current, 'user', (err) => {
        if (err.name === 'NotAllowedError') {
          logger.warn('Camera permission denied.', { error: err.message });
          navigate('/camera-permission');
          return;
        }
        logger.warn(
          loggerMessages.agePrediction.warn.videoSource,
          {
            errorMessage: err.message,
            type: 'ERR_SET_VIDEO_SOURCE',
            aggregates: {
              errorCode: AgePredictionErrorCodes.ERR_SET_VIDEO_SOURCE,
              phaseType: 'age-prediction:error',
            },
          },
        );
        navigate(`/exit?error=1&errorCode=${AgePredictionErrorCodes.ERR_SET_VIDEO_SOURCE}`);
      });
      setLoading(false);
    };
    awaitSetVideoSource();
  }, [navigate]);

  useEffect(() => {
    if ((videoRef.current !== null) && (!videoElReady || status === 'complete' || status === 'timedOut')) {
      return;
    }
    // Reset state on mount just in case
    // the user navigates back to this page
    human.resetAgePrediction();
    const video = videoRef.current;
    setTimeout(() => {
      // Waiting 1 second before starting age prediction because
      // starting it immediately after the video element is ready
      // can cause the key face to be captured before the camera
      // has adjusted to the lighting resulting in a dark image
      human.recursiveAgePrediction(Date.now(), video as HTMLVideoElement);
    }, 1000);
  }, [videoElReady, human, status]);

  useEffect(() => {
    if (status === null) {
      return;
    }

    // If we have no face readings we cannot face match with the ID.
    // Sent to error page
    if (!faces.length) {
      mixpanel.trackAgePrediction({ event: 'Age Prediction No Face Readings', status });
      logger.warn(loggerMessages.agePrediction.warn.noFaceTimeout, {
        aggregates: {
          redirectTo: 'exit',
          status,
          phaseType: 'age-prediction:no-face-timeout',
        },
      });
      stopStreamAndNavigate(`/exit?error=1&errorCode=${AgePredictionErrorCodes.ERR_NO_FACE_TIMEOUT}`);
      return;
    }

    // If the user timed out but we have at least one face reading
    // Send to ID verification
    if (status === 'timedOut') {
      mixpanel.trackAgePrediction({ event: 'Age Prediction Timeout -> ID Upload' });
      const nextRoute = '/prepare-id-verification';
      logger.warn(loggerMessages.agePrediction.warn.noProgressTimeout, {
        aggregates: {
          status,
          phaseType: 'age-prediction:no-progress-timeout',
          nextRoute,
        },
      });
      stopStreamAndNavigate(nextRoute);
      return;
    }

    // If the user is estimated too young
    // Send to ID verification
    if (!age || (age < threshold) || skipAgeDetection) {
      mixpanel.trackAgePrediction({ event: 'Age Predicted Below Threshold', predictedAge: age });
      const nextRoute = '/prepare-id-verification';
      logger.info(loggerMessages.agePrediction.info.predicted, {
        aggregates: {
          redirectTo: nextRoute,
          status,
          aboveThreshold: false,
          age: age ?? 0,
          threshold,
          phaseType: 'age-prediction:too-low',
        },
      });
      stopStreamAndNavigate(nextRoute);
      return;
    }

    // The user is estimated to be above the threshold
    // Send to success page
    mixpanel.trackAgePrediction({ event: 'Age Predicted Above Threshold', predictedAge: age });
    logger.info(loggerMessages.agePrediction.info.predicted, {
      aggregates: {
        redirectTo: 'exit',
        status,
        aboveThreshold: true,
        age,
        threshold,
        phaseType: 'age-prediction:success',
      },
    });

    // wait 500ms second for the circle to display fully green
    // before moving on to the next step
    stopStreamAndNavigate('/exit');
  }, [stopStreamAndNavigate, status, age, threshold, skipAgeDetection, faces]);

  const handleLoadedMetadata = useCallback(() => {
    setVideoElReady(true);
  }, [setVideoElReady]);

  /**
   * We must use the values from getBoundingClientRect()
   * for the arc SVGs to be positioned correctly.
   * I am not sure why but it works
   * Using the values from video width and height does not work
   */
  const videoRect = videoRef.current?.getBoundingClientRect();
  const w = videoRect ? videoRect.width : 0;
  const h = videoRect ? videoRect.height : 0;
  const cx = videoRect ? Math.ceil(w / 2) : 0;
  const cy = videoRect ? Math.ceil(h / 2) : 0;
  const r = cx > cy ? cy : cx;
  const completionCount = human.faceAngles.filter((a) => a.completed).length;
  const neededAngles = numAngles * percentToComplete;

  return (
    <div className="relative flex-1">
      <div className="flex justify-center mt-4">
        <div className="relative">
          <video
            id={id}
            ref={videoRef}
            data-testid="age-prediction-video"
            style={{
              transform: 'rotateY(180deg)',
              visibility: videoElReady ? 'visible' : 'hidden',
            }}
            className="object-cover w-full h-full border-2 border-main"
            onLoadedMetadata={handleLoadedMetadata}
            playsInline
            autoPlay
            muted
          />
          {step2Variant === 'FaceAngles' && completionCount < neededAngles && videoElReady && (
            <FaceAngleMessage
              completionCount={completionCount}
              centered={centered}
              faceDetected={faceDetected}
              step={step}
              numAngles={numAngles}
            />
          )}
          <CircleMaskSVG
            width={w}
            height={h}
            radius={r}
          />
          {step2Variant !== 'FaceAngles' && step === 2 && (
            <ProgressCircleSVG
              width={w}
              height={h}
              radius={r}
              data-testid="progress-circle"
            />
          )}
          {step2Variant === 'FaceAngles' && step === 2 && (
            <>
              {/* <EulerArcSVG
                euler="yaw"
                radius={r}
                cx={cx}
                cy={cy}
              /> */}
              {/* <EulerArcSVG
                euler="pitch"
                radius={r}
                cx={cx}
                cy={cy}
              /> */}
              {/* <RotationSVG
                width={w}
                height={h}
                radius={r}
              /> */}

              <CompleteCircleSVG
                cx={cx}
                cy={cy}
                radius={r}
                hidden={progress !== 100}
              />
              {progress !== 100 && (
                human.faceAngles.map((a) => (
                  a.completed ? (
                    <ProgressArcSVG
                      key={a.angle}
                      cx={cx}
                      cy={cy}
                      radius={r}
                      startAngle={a.vector[0]}
                      length={a.vector[1]}
                    />
                  ) : null
                ))
              )}
            </>
          )}

          {(!videoElReady || loading) && (
            <div className="flex justify-center items-center">
              <FullscreenLoader message={t('agePrediction.settingUpCamera')} />
            </div>
          )}

          {step === 3 && (
            <>
              {!!cx && !!cy && <BottomArc color={down ? 'green' : 'red'} center={[cx, cy]} radius={r} />}
              {!!cx && !!cy && <LeftArc color={left ? 'green' : 'red'} center={[cx, cy]} radius={r} />}
              {!!cx && !!cy && <RightArc color={right ? 'green' : 'red'} center={[cx, cy]} radius={r} />}
              {!!cx && !!cy && <TopArc color={up ? 'green' : 'red'} center={[cx, cy]} radius={r} />}

              {!left && <LeftArrow />}
              {left && !right && <RightArrow />}
              {left && right && !up && <LeftArrow className="rotate-90" />}
              {left && right && up && !down && <RightArrow className="rotate-90" />}
            </>
          )}
        </div>
      </div>

      {videoElReady && step2Variant === 'FaceStraight' && (
        <DetectionMessage
          step={step}
          left={left}
          right={right}
          up={up}
          down={down}
          faceDetected={faceDetected}
          centered={centered}
        />
      )}

      {/* These conditionals below are only used for debugging */}
      {infiniteFaceCollection && (
        <>
          <Text className="text-white" id="age-debug-lively">
            ...
          </Text>
          <Text className="text-white" id="age-debug-human">
            ...
          </Text>
        </>
      )}
      {displayKeyFace && (
        <div className="flex justify-center" id="keyFaceImage" />
      )}
    </div>
  );
}

export default AgePrediction;
