import {
  VerifyIdErrorCodes, FaceMatchErrorCodes, Au10tixErrorCodes, AgePredictionErrorCodes,
} from 'types/logger';

/* eslint-disable max-len */
export default {
  invalidRequest: 'Solicitud inválida',
  pageNotFound: 'No se encontró la página',
  and: 'y',
  or: 'o',
  instruction: {
    useIdOrLicense: 'Utilice cualquier licencia de conducir o documento de identidad emitido por el estado.',
  },
  loadingPage: {
    title: 'Nos estamos ocupando de que el proceso de verificación sea seguro y privado.',
    complete: '¡COMPLETADO!',
    personal: 'Sus datos personales no se recopilan, almacenan ni comparten en ningún momento.',
    init: 'Inicializando',
    pleaseWait: 'Espere a que finalice el proceso de inicialización.',
    safari: 'El proceso de inicialización puede tardar más en iOS Safari.',
  },
  entry: {
    title: 'Verificación de edad segura',
    usingAI: 'Con ayuda de IA avanzada, intentaremos verificar su edad basándonos en su apariencia.',
    noBio: 'No se transmiten ni almacenan datos biométricos.',
    ifUnsuccessful: 'Si no se puede verificar, le guiaremos a través de un proceso seguro y anónimo de verificación de su identidad.',
    start: 'Iniciar la verificación',
  },
  cameraEntry: {
    title: 'Verifiquemos su identidad',
    firstAttempt: 'Intentaremos primero verificar su edad mediante un rápido escaneado fotográfico, utilizando tecnología de IA.',
    noBio: 'No se transmiten ni almacenan datos biométricos',
    ready: '¿Listo(a) para proceder?',
    allowCamera: 'Permitir el acceso a la cámara',
  },
  prepareIdVerification: {
    title: '¡Felicidades por su apariencia juvenil!',
    description: 'Necesitamos más datos para verificar su edad, así que utilice un documento de identidad o pasaporte.',
    restEasy: 'Descanse con tranquilidad. Su información personal está siempre protegida con nosotros.',
    continue: 'Continuar',
  },
  footer: {
    title: 'Verificado por',
    terms: 'Términos y condiciones',
    privacy: 'La política de privacidad',
    biometric: 'Biometría',
    copyright: 'Copyright',
    rights: 'Todos los derechos reservados.',
  },
  error: {
    mintTag: 'Error al emitir la etiqueta',
    generic: 'Ocurrió un error. Inténtelo de nuevo.',
    noFileSelected: 'No se seleccionó ningún archivo',
    dynamic: {
      [VerifyIdErrorCodes.ERR_LOAD_IMAGE]: 'Ocurrió un error. Inténtelo de nuevo.',
      [VerifyIdErrorCodes.ERR_DETECT_NONE]: 'No se encontró ningún documento de identidad en la foto proporcionada. Asegúrese de que la foto corresponde a un documento de identidad legible y en vigor que permita su identificación.',
      [VerifyIdErrorCodes.ERR_DETECT_MULTIPLE]: 'Se detectaron varias identificaciones, proporcione solo una para su identificación.',
      [VerifyIdErrorCodes.ERR_DETECT_MUGSHOT]: 'No se encontró ninguna foto válida de identificación en el documento de identidad. Inténtelo de nuevo.',
      [VerifyIdErrorCodes.ERR_DETECT_DATE]: 'No pudimos verificar las fechas en su foto. Suba otra foto y asegúrese de que resulten visibles.',
      [VerifyIdErrorCodes.ERR_DETECT_DATE_THRESHOLD]: 'No se detectaron fechas dentro del rango de edad.',
      [VerifyIdErrorCodes.ERR_DETECT_FACE]: 'Error al redimensionar la foto del documento de identidad y detectar la cara',
      [VerifyIdErrorCodes.ERR_DETECT_DATE_FUTURE]: 'No se encontró una fecha de caducidad válida. Inténtelo de nuevo.',
      [FaceMatchErrorCodes.ERR_FACE_MATCH_ID]: 'La foto del documento de identidad no coincide con su apariencia. Inténtelo de nuevo.',
      [FaceMatchErrorCodes.ERR_MATCHING_FACES]: 'Se produjo un error al reconocer su cara. Inténtelo de nuevo.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AGE]: 'AU10TIX: No mayor de 18 años.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AUTHENTIC]: 'AU10TIX: Identificación no auténtica.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_EXPIRED]: 'AU10TIX: Documento de identidad caducado.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_MAX_ATTEMPTS]: 'AU10TIX: Máximo de intentos alcanzado.',
      [AgePredictionErrorCodes.ERR_RECURSIVE_AGE_PREDICTION]: 'Error al predecir la edad.',
      [AgePredictionErrorCodes.ERR_SET_VIDEO_SOURCE]: 'Error al configurar la fuente de video. Compruebe su cámara.',
      [AgePredictionErrorCodes.ERR_NO_FACE_TIMEOUT]: 'No se pudo predecir la edad porque no se detectó ningún rostro.',
    },
  },
  failure: {
    title: '¡Falló la verificación!',
    errorMessage: 'Ocurrió un problema al intentar verificar su edad. Inténtelo de nuevo.',
    tryAgain: 'Intentar de nuevo',
    submitForReview: 'Enviar para revisión manual',
    returnToSite: 'Volver al sitio',
  },
  success: {
    title: 'Felicidades, se verificó correctamente su edad.',
    description: 'Ha verificado correctamente su edad.',
    continueToSite: 'Continuar al sitio',
  },
  appeal: {
    processing: 'Procesando...',
    confirm: {
      photo: {
        title: 'Confirmar foto',
        description: 'Para realizar la verificación manual, confirme la foto de su cara que aparece a continuación. Si no está satisfecho con la imagen actual, puede volver a tomarla para obtener los mejores resultados.',
        confirmAndProceed: 'Confirmar y continuar',
        retakeNewPhoto: 'Volver a tomar la foto',
        takePhoto: 'Tomar foto',
        errorAndRetake: 'Error al tomar la foto. Vuelva a tomar la foto.',
      },
      id: {
        title: 'Confirme su ID',
        description: 'Ahora, confirmemos la imagen de su documento. Verifique que todos los datos sean claros y legibles. Si cree que sería mejor utilizar otra foto, puede subir una nueva ahora.',
        confirmAndProceed: 'Confirmar y continuar',
        uploadNewIdPhoto: 'Cargar nueva foto',
        uploadPhoto: 'Cargar una foto',
      },
    },
    success: {
      title: 'Completado',
      description: '¡Gracias! Recibimos su información y nos comunicaremos con usted en breve.',
    },
    manualSubmission: {
      title: 'Presentación de verificación manual',
      description: 'Sus datos biométricos se usarán solo para verificar su edad, como se indica en nuestros términos y condiciones y en nuestra política de privacidad.',
      submitForManualReview: 'Enviar para revisión manual',
      emailAddress: 'Dirección de correo electrónico',
      message: 'Mensaje (opcional)',
      agreedToTermsAndConditionsAndPrivacyPolicy: 'Al marcar esta casilla, acepta',
      termsAndConditions: 'Los términos y condiciones',
      privacyPolicy: 'La política de privacidad',
    },
  },
  a10tix: {
    title: 'Verificar identidad',
    pending: 'Verificación pendiente',
    verificationPending: 'Verificación pendiente',
    verificationPendingDescription: 'Puede demorar hasta un minuto',
    takingLongTimeMessage: 'Está demorando más de lo habitual. Para agilizar el proceso puede subir una nueva foto de su documento de identidad. Compruebe que la foto no salga borrosa y esté bien iluminada.',
    uploadNewPhoto: 'Cargar foto nueva',
    submit: 'Enviar',
    takeAPhoto: 'Tomar una foto',
    takeADifferentPhoto: 'Tomar otra foto',
  },
  cameraPermission: {
    description: 'Se requiere permiso de cámara para usar esta función.',
    denied: 'Parece que ha denegado el acceso a la cámara para esta página web. Debe actualizar la configuración de su navegador y volver a cargar la página.',
    goBack: 'Regresar',
  },
  detectionMessage: {
    'Please move your face into the frame': 'Coloque la cara en el encuadre',
    'Turn your head to the left': 'Gire su cabeza hacia la izquierda',
    'Turn your head to the right': 'Gire su cabeza hacia la derecha',
    'Tilt your head up': 'Incline su cabeza hacia arriba',
    'Tilt your head down': 'Incline su cabeza hacia abajo',
    'Look straight into the camera': 'Mire directamente a la cámara',
    'Move your head slowly to fill in the circle': 'Mueva su cabeza lentamente para rodear el círculo',
  },
  reviewRequest: {
    title: 'Solicitud de revisión',
    email: 'Correo electrónico',
    message: 'Mensaje',
    idImage: 'Imagen del documento de identidad',
    userImage: 'Imagen del usuario',
    requestApproved: 'Solicitud aprobada',
    requestDenied: 'Solicitud denegada',
    approve: 'Aprobar',
    deny: 'Denegar',
    processing: 'Procesando...',
  },
  licenses: {
    title: 'Fotos de licencia',
    loading: 'Cargando…',
    deleteAll: 'Borrar todo',
  },
  license: {
    emblemState: 'Estado de emblema:',
    ocrStatus: 'Estado de OCR:',
    fileId: 'ID de archivo:',
    createdAt: 'Fecha de creación:',
    uploadReason: 'Motivo de carga:',
    status: 'Estado:',
    ocrFailureReason: 'Motivo del fallo de OCR:',
  },
  idPhotoGuidelines: {
    title: 'Tenga en cuenta lo siguiente:',
    placeId: 'Coloque el documento de identidad sobre una superficie plana y de un solo color.',
    ensureGlareFree: 'La foto debe estar bien iluminada y sin reflejos.',
    allEdges: 'Todos los bordes del documento deben estar dentro del marco.',
  },
  takePhoto: {
    rotateGuide: 'Guía de rotación',
    setIdFlat: 'Compruebe que el documento esté en una superficie plana, bien visible y con todos los bordes dentro del marco.',
    cancel: 'Cancelar',
  },
  uploadPhoto: {
    uploadPhoto: 'Cargar foto',
  },
  verifyId: {
    noFaceDetected: 'No se detecta ningún rostro en la foto cargada',
    idShouldBeVisible: ' Su documento debe poder verse y leerse perfectamente en la foto.',
    submit: 'Enviar',
    next: 'Siguiente',
    retakePhoto: 'Volver a tomar la foto',
    rescanFace: 'Volver a escanear el rostro',
    verificationInProgress: 'Verificación en curso...',
    pleaseWait: 'Espere mientras se verifica su foto. Esto debería tomar solo unos segundos.',
    chooseId: 'Seleccione su tipo de identificación',
    idCard: 'Permiso de conducir o documento nacional de identidad',
    germanIdCard: 'Documento nacional de identidad alemán',
    passport: 'Pasaporte',
    selectCountry: 'Seleccione el país de emisión del documento:',
    continue: 'Continuar',
    frontOfId: 'Tome una foto del anverso de su documento de identidad',
    faceNoMatch: 'El rostro que aparece en el documento de identidad no coincide con su apariencia.',
    informationSecure: 'Su información permanece segura y confidencial.',
    scanFailure: {
      title: 'Verificación fallida',
      unable: 'Lo sentimos, pero no pudimos verificar su edad con el documento proporcionado. Intentémoslo de nuevo.',
    },
  },
  agePrediction: {
    settingUpCamera: 'Preparando su cámara',
  },
  human: {
    errorInitializing: 'Error al iniciar',
    errorLoadingModel: 'Error al cargar el modelo',
    pleaseCloseWindowAndTryAgain: 'Cierre esta ventana e inténtelo de nuevo',
    incompatibleWebview: 'El navegador actual no es compatible. Si está en una vista web, abra el enlace en un navegador.',
  },
  verifyIdInfo: {
    passport: {
      title: 'Escanee la página de identificación de su pasaporte',
      // @todo: atg translation needed
      // instruction: 'Instrucción para pasaportes',
      instruction: [
        '• Coloque el documento plano y asegúrese de una buena iluminación',
        '• Ajuste todo el documento dentro del marco',
        '• Alinee el código en la parte inferior con la guía',
      ].join('<br/>'),
    },
    'non-us-id': {
      title: 'Escanee el reverso de su documento de identidad',
      // @todo: atg translation needed
      // instruction: 'Instrucción para documentos de identidad no estadounidenses',
      instruction: [
        '• Coloque el documento plano y asegúrese de una buena iluminación',
        '• Ajuste todo el documento dentro del marco',
        '• Alinee el código en la parte inferior con la guía',
      ].join('<br/>'),
    },
    'us-id': {
      title: 'Escanee el reverso de su documento de identidad',
      instruction: 'Instrucción para documentos de identidad estadounidenses',
    },
    scanBarcode: 'Busque y escanee este código de barras',
    cantFindBarcode: '¿No encuentra el código de barras en su documento de identidad? Toque "Omitir" para continuar.',
    skipBarcode: 'Omitir',
  },
  faceAngleMessage: {
    moveFace: 'Coloque la cara <br />  en el encuadre',
    lookStraight: 'Mire directamente <br /> a la cámara',
    turnHead: 'Gire su cabeza',
    keepTurningClockwise: 'Gira tu cabeza en el <br /> sentido del reloj',
  },
  signup: {
    addBillingInfo: 'Agregar información de facturación',
    billingDescription: 'Para completar su integración con SafePassage, proporcione sus datos de facturación. Esta información se utilizará para fines de facturación relacionados con los servicios de SafePassage vinculados con Emblem.',
    noCreditCardRequired: 'No se requiere tarjeta de crédito en este momento. Se le facturará según el uso.',
    accessibleThroughEmblem: 'Todos los detalles de uso e información de facturación estarán accesibles a través de Emblem.',
    companyNameLabel: 'Nombre de la empresa (o nombre completo si no representa a una organización)',
    companyNamePlaceholder: 'ej., Acme Corp / Juan Pérez',
    billingEmailLabel: 'Correo electrónico de contacto para facturación',
    billingEmailPlaceholder: 'ej., facturacion@acmecorp.com',
    billingEmailHelper: 'Las facturas y la información de facturación se enviarán aquí.',
    billingAddressLabel: 'Dirección de facturación',
    billingAddressPlaceholder: 'Dirección, Ciudad, Estado/Provincia, Código Postal, País',
    billingAddressHelper: 'Por favor, ingrese una dirección completa para fines de facturación.',
    phoneNumberLabel: 'Número de teléfono (Opcional)',
    phoneNumberPlaceholder: 'ej., +1 234 567 890',
    phoneNumberHelper: 'Para seguimientos relacionados con la facturación, si es necesario.',
    termsAgreement: 'Acepto que se me facture por los servicios de SafePassage conectados a Emblem a $0.08 por cada verificación de edad exitosa. No se requiere tarjeta de crédito en este momento, y acepto los términos del Acuerdo de Pago.',
    completeSignup: 'Completar registro',
    needHelp: '¿Necesita ayuda?',
    contactSupport: 'Contáctenos en support@safepassage.com',
  },
};
