import React, { useEffect } from 'react';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Text from 'components/Text';
import { Link } from 'react-router-dom';
import logger from 'services/logger';
import { loggerMessages } from 'types/logger';
import { useTranslation } from 'react-i18next';
import LockSVG from 'components/svgs/LockSVG';
import localStorageUtil from 'utils/localStorageUtil';
import mixpanel from 'services/mixpanel';

function EntryRoute() {
  const { t, i18n } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const lng = searchParams.get('lng') ?? localStorageUtil.getValues().lng ?? '';

  useEffect(() => {
    if (lng) { i18n.changeLanguage(lng); }
  }, [i18n, lng]);

  useEffect(() => {
    logger.info(loggerMessages.phases.info.entry, { aggregates: { phaseProgress: true } });
    logger.flush();
    mixpanel.trackEvent({ event: 'Safe Passage Landing Au10tix' });
  }, []);

  return (
    <div className="flex flex-col items-center gap-6 w-full">
      <div className="max-w-md text-center">
        <Heading className="mb-4 text-2xl">
          {t('entry.title')}
        </Heading>
        <Text size="lg" className="mb-4">
          {t('entry.usingAI')}
          {' '}
          <span className="font-bold">
            {t('entry.noBio')}
          </span>
        </Text>
        <div className="flex justify-center">
          <LockSVG
            className="lg:h-40 md:h-40 sm:h-40 h-28 w-auto mb-4 lg:h-"
          />
        </div>
        <div className="mb-6 p-4 rounded h-36 flex items-center justify-center " style={{ backgroundColor: '#5184D126' }}>
          <Text className="text-center">
            {t('entry.ifUnsuccessful')}
          </Text>
        </div>
        <div className="space-y-3">
          <Button
            component={Link}
            to="verify-id"
            className="mb-2"
            variant="primary"
          >
            {t('entry.start')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EntryRoute;
