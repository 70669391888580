import React, {
  createContext, useContext, useState, useEffect, useMemo,
} from 'react';
import useConfigStore from 'stores/configStore';
import ErrorLayout from 'layouts/ErrorLayout';
import HumanAgePrediction from 'services/human/Human';
import Text from 'components/Text';
import { useTranslation } from 'react-i18next';
import LoadingPage from 'components/LoadingPage';
import useAgeDetectionStore from 'stores/ageDetectionStore';
import useProgressStore from 'stores/progressStore';
import { Navigate } from 'react-router-dom';
import mixpanel from 'services/mixpanel';
import { getMessageFromError } from 'utils/errorMessage';

const HumanContext = createContext<HumanAgePrediction | null>(null);

function HumanAgePredictionProvider({ children }: { children: React.ReactNode }) {
  const humanConfig = useConfigStore((state) => state.humanConfig);
  const debugConfig = useConfigStore((state) => state.debug);
  const setSimilarity = useAgeDetectionStore((state) => state.setSimilarity);
  const setFaces = useAgeDetectionStore((state) => state.setFaces);
  const setCentered = useAgeDetectionStore((state) => state.setCentered);
  const setFaceDetected = useAgeDetectionStore((state) => state.setFaceDetected);
  const setAge = useAgeDetectionStore((state) => state.setAge);
  const setLiveScore = useAgeDetectionStore((state) => state.setLiveScore);
  const setRealScore = useAgeDetectionStore((state) => state.setRealScore);
  const resetAgeDetectionStore = useAgeDetectionStore((state) => state.reset);
  const setAngles = useAgeDetectionStore((state) => state.setAngles);
  const setPitchUp = useAgeDetectionStore((state) => state.setPitchUp);
  const setPitchDown = useAgeDetectionStore((state) => state.setPitchDown);
  const setYawLeft = useAgeDetectionStore((state) => state.setYawLeft);
  const setYawRight = useAgeDetectionStore((state) => state.setYawRight);
  const setStatus = useAgeDetectionStore((state) => state.setStatus);
  const setStep = useAgeDetectionStore((state) => state.setStep);
  const setDisableAgePrediction = useAgeDetectionStore((state) => state.setDisableAgePrediction);
  const displaySimilarity = useConfigStore((state) => state.debug.displaySimilarity);

  const setProgress = useProgressStore((state) => state.setProgress);
  const { t } = useTranslation();

  // State
  const [initialized, setInitialized] = useState(false);
  const [human, setHuman] = useState<HumanAgePrediction | null>(null);
  const [error, setError] = useState<boolean | null>(null);
  const [onnxError, setOnnxError] = useState<string | null>(null);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [agePredictionErrorNext, setAgePredictionErrorNext] = useState('');

  useEffect(() => {
    setInitialized(false);
    setHuman(null);
    const humanInstance = new HumanAgePrediction(humanConfig, debugConfig);
    humanInstance.on('initialized', () => {
      setInitialized(true);
    });

    humanInstance.on('initError', (err) => {
      const errorMessage = getMessageFromError(err);
      mixpanel.trackEvent({ event: 'Human Initialization Error', errorMessage });
      setError(true);
    });

    humanInstance.on('initOnnxError', (err) => {
      /**
       * If ONNX fails to initialie we disable age prediction
       * and the user must upload their ID to verify their age.
       * The user still goes through the liveness check and we
       * match their face readings from Human with their ID
       *
       * This usually happens when the users is on an older OS
       * like Android 10 or iOS 14
       */
      const errorMessage = getMessageFromError(err);
      mixpanel.trackEvent({ event: 'Onnx Load Error', errorMessage });
      setOnnxError(errorMessage);
      setDisableAgePrediction(true);
    });

    humanInstance.on('error', (errorMessage: string) => {
      mixpanel.trackEvent({ event: 'Human Error', errorMessage });
      setError(true);
    });

    const setUp = async () => {
      await humanInstance.init();
      setHuman(humanInstance);
    };
    setUp();
    return () => {
      humanInstance.agePredictor.onnxWorker?.worker.terminate();
      humanInstance.humanWorker?.worker.terminate();
    };
  }, [humanConfig, debugConfig, setDisableAgePrediction]);

  useEffect(() => {
    if (!human) {
      return;
    }
    human.on('realScore', (score) => {
      setRealScore(score);
    });

    human.on('liveScore', (score) => {
      setLiveScore(score);
    });

    human.on('step', (s) => {
      setStep(s);
    });

    human.on('progress', (p) => {
      setProgress(p);
    });

    human.on('faceDetected', (f) => {
      setFaceDetected(f);
    });

    human.on('centered', (c) => {
      setCentered(c);
    });

    human.on('angles', (a) => {
      setAngles(a);
    });

    human.on('pitchUp', (p) => {
      setPitchUp(p);
    });

    human.on('pitchDown', (p) => {
      setPitchDown(p);
    });

    human.on('yawLeft', (y) => {
      setYawLeft(y);
    });

    human.on('yawRight', (y) => {
      setYawRight(y);
    });

    human.on('status', (s) => {
      setStatus(s);
    });

    human.on('age', (a) => {
      setAge(a);
    });

    human.on('faces', (f) => {
      setFaces(f);
    });

    human.on('faceMatchResult', (result) => {
      if (displaySimilarity) setSimilarity(result.similarity);
    });

    human.on('agePredictionError', ({ nextLocation }) => {
      setAgePredictionErrorNext(nextLocation);
    });

    human.on('reset', () => {
      resetAgeDetectionStore();
      setProgress(0);
    });
  }, [human]);

  const value = useMemo(() => human, [human]);

  if (agePredictionErrorNext) {
    return (
      <Navigate to={agePredictionErrorNext} replace />
    );
  }

  if ((error && onnxError) || (onnxError === 'no available backend to use')) {
    // Both humand and onnx were unable to initialize
    // likely due to incompatible browser
    return (
      <ErrorLayout>
        <div className="flex flex-col justify-center content-center text-center w-full">
          <h2 className="text-2xl mb-4">{t('human.errorInitializing')}</h2>
          <Text>{t('human.incompatibleWebview')}</Text>
        </div>
      </ErrorLayout>
    );
  }

  if (error) {
    return (
      <ErrorLayout>
        <div className="flex flex-col justify-center content-center text-center w-full">
          <h2 className="text-2xl mb-4">{t('human.errorInitializing')}</h2>
          <Text>{t('human.pleaseCloseWindowAndTryAgain')}</Text>
        </div>
      </ErrorLayout>
    );
  }

  if (human === null || !initialized || !animationComplete) {
    return (
      <LoadingPage
        initialized={initialized}
        setAnimationComplete={setAnimationComplete}
        animationComplete={animationComplete}
      />
    );
  }

  return (
    <HumanContext.Provider value={value}>
      {children}
    </HumanContext.Provider>
  );
}

function useHumanAgePredictor() {
  const value = useContext(HumanContext);
  if (value === null) {
    throw new Error('useHumanAgePredictor must be used within a HumanProvider');
  }
  return value;
}

export { useHumanAgePredictor, HumanAgePredictionProvider };
